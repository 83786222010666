import { __awaiter } from "tslib";
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './theme/base_layout_desktop.css';
/* Theme variables */
import './theme/variables.css';
import './theme/core.css';
/* Theme color */
//import './theme/color_green.css';
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import VueApp from '@/VueApp.vue';
import router from '@/router';
import heap from '@/heap';
import BaseLayout from '@/components/BaseLayout.vue';
import BaseLayoutDesktop from '@/components/BaseLayoutDesktop.vue';
import jQuery from "jquery";
import Topic from '@/scripts/Topic.js';
import User from '@/scripts/User.js';
import Utils from '@/scripts/Utils.js';
import Order from '@/scripts/Order.js';
import Metrics from '@/scripts/Metrics.js';
import Push from '@/scripts/Push.js';
import Capgo from '@/scripts/Capgo.js';
import './registerServiceWorker';
import { toastController, alertController } from '@ionic/vue';
import { App } from '@capacitor/app';
const FlashNotice = {
    queue: [],
    idle: true,
    push(message) {
        if (this.queue[this.queue.length - 1] == message) {
            return;
        }
        this.queue.push(message);
        this.next();
    },
    next() {
        if (!this.idle) {
            return;
        }
        const message = this.queue.join("\n");
        this.queue = [];
        if (!message) {
            return;
        }
        this.flash(message);
    },
    flash(message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.idle = false;
            const self = this;
            const toast = yield toastController
                .create({
                message: message,
                duration: 2000,
                color: 'dark',
                position: 'bottom'
            });
            toast.present();
            toast.onDidDismiss().then(() => {
                self.idle = true;
                self.next();
            });
        });
    }
};
const flash = (message) => {
    FlashNotice.push(message);
};
let globalAlertPrompt;
const alert = (message, title) => __awaiter(void 0, void 0, void 0, function* () {
    Topic.publish('dismissModal');
    if (globalAlertPrompt) {
        globalAlertPrompt.dismiss();
    }
    globalAlertPrompt = yield alertController
        .create({
        header: title,
        message: message,
        translucent: true,
        buttons: ['Ok'],
    });
    yield globalAlertPrompt.present();
    return yield globalAlertPrompt.onDidDismiss();
});
const go = (route) => __awaiter(void 0, void 0, void 0, function* () {
    yield router.push(route);
});
jQuery(document).ajaxError((event, jqxhr, settings, thrownError) => {
    const status_code = jqxhr.status;
    if (status_code == 403) {
        if (heap.getters.userIsLogged) {
            flash('К сожалению, нет прав для этого действия');
        }
        else {
            Topic.publish('dismissModal');
            flash('Вы не выполнили вход, пожалуйста авторизируйтесь');
            router.push({ path: `/modal/user-authorize` });
        }
    }
    else if (status_code == 401) {
        flash('Вы не выполнили вход, пожалуйста авторизируйтесь');
        Topic.publish('dismissModal');
        router.push({ path: `/modal/user-authorize` });
    }
    if (status_code == 0) {
        //flash('Похоже нет связи с интерентом.');
        Topic.publish('dismissModal');
        //router.push({path: `/error-offline`});
    }
    else if (thrownError === 'abort') {
        //flash('Похоже нет связи с интерентом.');
        //router.push({path: `/error-offline`});
    }
    else if (thrownError === 'timeout') {
        //flash('Похоже нет связи с интерентом.');
        //router.push({path: `/error-offline`});
    }
});
jQuery(document).ajaxSend(() => {
    heap.commit('setInteractionStatus', 1);
});
jQuery(document).ajaxComplete((e, xhr) => {
    const sid = xhr.getResponseHeader('x-sid');
    if (sid) {
        User.sessionIdUse(sid);
    }
    setTimeout(() => {
        heap.commit('setInteractionStatus', -1);
    }, 100);
});
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.onmessage = (event) => {
        var _a;
        const { data } = (_a = event.data) !== null && _a !== void 0 ? _a : {};
        const { title, body, topic, type } = data !== null && data !== void 0 ? data : {};
        if (topic) {
            Topic.publish(topic, data);
        }
        else if (type === 'flash') {
            flash(body);
        }
        else if (body) {
            alert(body, title);
        }
        else {
            console.log('UNHANDLED WEBPUSH', data);
        }
    };
}
const app = createApp(VueApp)
    .use(IonicVue)
    .use(router)
    .use(heap);
app.provide("$Order", Order);
app.config.globalProperties.$heap = heap;
app.config.globalProperties.$flash = flash;
app.config.globalProperties.$alert = alert;
app.config.globalProperties.$topic = Topic;
app.config.globalProperties.$go = go;
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
if (isMobile) {
    app.component('base-layout', BaseLayout);
}
else {
    app.component('base-layout', BaseLayoutDesktop);
}
function startApp() {
    return __awaiter(this, void 0, void 0, function* () {
        /**
         * Signing in first is slower but is more solid because all requests will go with session header
         */
        const sessionId = yield Utils.pref.get('sessionId');
        if (sessionId) {
            yield User.sessionIdUse(sessionId);
        }
        app.mount('#app');
        /**
         * opens deeplinking urls in this app
         */
        App.addListener('appUrlOpen', function (event) {
            const slug = event.url.split('.com').pop();
            if (slug) {
                go(slug);
            }
        });
        yield User.autoSignIn();
        Metrics.init();
        Push.setFlashHandler(flash);
        Push.setAlertHandler(alert);
        Push.setGoHandler(go);
        Capgo.init(App, flash);
    });
}
startApp();
